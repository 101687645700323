<template> 
 <div>   
            <v-row dense>
              <v-col class="overline text-center ma-0 pa-0">
                Elija el tipo de servicio
              </v-col>
            </v-row>         
             <v-row dense class="pa-0 ma-0">
                      <v-col cols=12>
                              <v-tabs slider-color="green" :color="colorMenu" class="ma-0 pa-0"
                                      dense
                                      icons-and-text                                      
                                      grow
                                      v-model="tabs"                                      
                                    >                                  
                                      <v-tab
                                            dense
                                            class="caption pa-0"
                                            v-for="(itema) in categoriasTravel"
                                            :key="'#tab-' + itema.idcategoria" @click="filtroPorCategoria(itema.idcategoria)" 
                                          >
                                            <v-row dense>
                                                     {{ itema.nombre }}
                                            </v-row>                   
                                            <v-avatar class="mt-4 pa-0" tile dense  size="30">
                                                      <v-img contain :src=pathImagen(itema.img)></v-img>
                                            </v-avatar>
                                      </v-tab>
                                </v-tabs>
                                 <v-tabs-items v-model="tabs" @change="cambio(tabs)">                                   
                                        <v-tab-item  class="mt-2" v-for="itemc in categoriasTravel"
                                                    :key="'tab-' + itemc.idcategoria"
                                        >
                                          <v-card flat>                                            
                                            <v-row dense>                                             
                                              <v-col cols=6 md=4 v-for="(itemb) in serviciosTravelFiltrados" :key=itemb.idservicio >
                                                  <v-card dense  class="elevation-5  pa-1 ma-1 caption  text-center" >
                                                          <v-row dense  v-if="itemb.img"   >
                                                            <v-col  class="py-0 px-auto overline pt-2" align="center" >
                                                                          <v-img width="120" :src=pathImagen(itemb.img) contain></v-img>
                                                           </v-col>
                                                          </v-row>
                                                          <v-row dense  >
                                                            <v-col  class="py-0 overline" >
                                                                 {{itemb.descripcion}}
                                                            </v-col>
                                                          </v-row>
                                                          <v-row dense  v-if="itemb.origen">
                                                            <v-col>
                                                                  Desde {{itemb.origen}}
                                                            </v-col>
                                                          </v-row>
                                                          <v-row dense v-if="itemb.destino">
                                                            <v-col>
                                                                  A {{itemb.destino}}
                                                            </v-col>
                                                          </v-row>
                                                          <v-row dense>
                                                            <v-col>
                                                              {{itemb.moneda}}{{itemb.costo}} {{itemb.determinaCosto}}
                                                             <v-btn dense class="px-1 mx-1" :color="colorMenu" outlined x-small> + Info</v-btn>                                                                                          
                                                            </v-col>
                                                          </v-row>
                                                          <v-row dense>
                                                            <v-col>
                                                                     <v-btn dark x-small :color="colorMenu" dense block class=" text-center" @click="agregarHuespedes()">   
                                                                            Elegir                                                                                                    
                                                                    </v-btn>
                                                            </v-col>
                                                          </v-row>                                                         
                                                 </v-card>
                                              </v-col>
                                            </v-row>                                               
                                          </v-card>
                                        </v-tab-item>
                                      </v-tabs-items>
                    </v-col>
             </v-row>
        </div>
</template>

<script>
import datosCategoriasTravel from "@/base/categoriasServiciosTravel.json";
import datosServiciosTravel from "@/base/listadoServiciosTravel.json";

export default {
  name:"serviciosContratar",
  data(){
       return{
              tabs:null,
                colorMenu:"#0E365C",
              mostrarTab:false,
              categoriasTravel:[],  
              serviciosTravel:[],
              serviciosTravelFiltrados:[]  
       }
  },
    created: function() {
                         this.categoriasTravel=datosCategoriasTravel
                         this.serviciosTravel=datosServiciosTravel
                         this.serviciosTravelFiltrados=this.serviciosTravel                       
  },
  mounted() {
      this.filtroPorCategoria(1)
    },
  methods:{
          pathImagen(imagen){
                                return  require('@/assets/'+imagen)  
          },
          colorEstado (estado) {
                                if (estado=="Pendiente") return 'red'
                                 else if (estado =="Completado") return 'green'
                                else return 'green'
                                },
          filtroPorCategoria(categoria){
                                 this.serviciosTravelFiltrados=[]
                                 this.serviciosTravelFiltrados=this.serviciosTravel.filter(item => {
                                        return item.idcategoria===categoria
                                })
                                return this.serviciosTravelFiltrados
          },
          cambio(item){
                    alert(item)
                    this.filtroPorCategoria(this.categoriasTravel[item].idcategoria)
          }   
  }
}
</script>
<style scoped>
    .borde {
 border-left: 5px solid green !important;
}
</style>