<template>
  <div>       
        <strong>Estado actual de nuestros servicios acorde a normativas y protocolos vigentes a la fecha: </strong>
    
        <ul>
          <li>
             Uso de la pileta: se encuentra habilitada con los protocolos COVID 19 vigentes con reserva de turnos de (45 “)
de 10AM a 21.45PM
          </li>
          <li>
            Uso de Sauna: Por (DNU N° 576/20) No se encuentra habilitado.
          </li>
          <li>
Uso de Vestuarios: Por (DNU N° 576/20) Se encuentran habilitados como vestidores y uso de Toilette. ( NO
duchas y Sauna clausurado)
          </li>
          <li>
Uso de Cancha de Paddle: NO se encuentra habilitado por las condiciones climáticas heladas con congelamiento
de la superficie de la misma.
          </li>
          <li>
Parque espacios verdes y bancos: Se encuentra habilitado.
          </li>
          <li>
Uso de GYM: Esta habilitado cumpliendo protocolos vigentes, uso con reserva de turnos de 10AM a 21.45PM
          </li>
          <li>
Sala de Video, Biblioteca habilitado: Solicitando turnos con cupos.
</li>
          <li>
Desayuno: El servicio se brinda al huésped con mozo, es a elección los insumos del buffet de 8.00 y las 11 Hs. Si
lo desea a la unidad es necesario solicitarlo telefónicamente (Int. 128). En caso de que no esté incluido en su
reserva, puede contratarlo con costo adicional.
          </li>
          <li>
 Limpieza de cabañas: con la finalidad de disminuir el contacto del personal de limpieza con sus objetos
personales y los ambientes particulares, el servicio de limpieza son los días Lunes , Miércoles y Viernes , y se
brindará solo si Ud. lo solicita. Los horarios se acordarán con Ud. Al momento del Check in. Dispondrán de un
kit de productos de desinfección y limpieza en su cabaña. Si el huésped solicita días adicionales lo coordina con
la recepción.
          </li>
          <li>
Rincón Gourmet: El restaurante brinda el servicio con reservas, Desayuno, Confitería, Meriendas, Cenas, el
mismo está habilitado por Bromatología al 50 % de su capacidad en mesas .Se mantiene la opción del servicio
de take away en el Deck , anexos del parque y a cabañas de 8.00 a 23 H. Interno 128.
          </li>
          <li>
Actividades de Recreación: Las mismas son en espacios cubiertos con distanciamiento Social de 10 a 22 Hs para
cada actividad específica recibirá las indicaciones del caso. Con cupos de participantes por actividades.
          </li>
          <li>
             Recreación Infantil (4 a 10 años): Las actividades son en la salita con distanciamiento Social y cupos de Niños
por Aforo de 14 a 20. Hs de domingo a viernes. Los chicos deben asistir con tapabocas.
          </li>
          <li>
Servicio de lavandería: no se ofrecerá el servicio de lavandería de prendas personales.
          </li>
          <li>
             Sala de Juegos: por el momento no se encuentra habilitado por las disposiciones sanitarias vigentes
          </li>
          <li>
             Juegos de mesa: lamentablemente no estarán disponibles esta temporada.
          </li>
          <li>
 Check out: el día previo a su partida se les enviará la liquidación de sus consumos al mail indicado en el Check
in. Sugerimos cancelar su cuenta el día anterior para facilitar la gestión.
          </li>
          <li>
            El Resort se reserva el derecho de permanencia y admisión durante su estadía en caso de no cumplir con estos
protocolos y normas de conducta establecidos. En caso de tener que permanecer en cuarentena se dispondrá
de habitación para el o los huéspedes afectados. El costo de la estadía y comidas estarán a cargo de los
huéspedes con la tarifa vigente al tipo de habitación ocupada y a la temporada correspondiente a su estadía
durante el período de cuarentena indicado por las autoridades sanitarias locales. Este plazo habitualmente es
de 14 días. Por medio de la presente afirmo conocer y aceptar los procedimientos de uso de los Resorts para
mantener una estadía segura. 
</li>          
        </ul>    
  </div>
</template>
<script>

export default {
  name:'protocolosServicios',
  data() {
    return {
   }}
}
</script>



