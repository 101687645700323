<template>
    <v-card class="pa-2"> 
      <v-card-text dense>
             ¿Presenta alguno de los siguientes síntomas?<br><br>
                          <v-row v-for="(pregunta) in preguntas" :key="pregunta.id" no-gutters>
                           <v-col xs=6 :md=pregunta.columnas>{{ pregunta.pregunta}}
                           </v-col>
                           <v-col >                             
                                         <v-radio-group class="ma-0 pa-0 pb-1" v-model="r3" row hide-details=""  >                                                
                                                <v-radio  v-for="(respuesta) in pregunta.respuestas" :key="respuesta" 
                                                  :label=respuesta
                                                  :value=respuesta                             
                                                ></v-radio>                                               
                                          </v-radio-group>                             
                           </v-col>
                          </v-row>     
      </v-card-text>
      </v-card>    
</template>
<script>

export default {
  name:'ddjj',
  data() {
    return {
      tituloHeader: 'Declaracion Jurada de Salud',
      idcomplejo:'1',
      complejo:'rda',
      colorMEnu:'2E7D32',
      preguntas:[{
                  id:'1',
                  pregunta:'Fibre 37,5 grados o más',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },                  
                  {
                  id:'2',
                  pregunta:'Dolor de garganta',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },
                   {
                   id:'3',
                  pregunta:'Tos',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },
                   {
                      id:'4',
                      pregunta:'Dificultad respiratoria',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },
                   {
                      id:'5',
                      pregunta:'Perdida de olfato o gusto',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },
                  
                   {
                      id:'6',
                      pregunta:'Neumonia',
                  respuestas:['Si','No'],
                  columnas:'2'
                  },
                  {
                     id:'7',
                    pregunta:'En caso de haber tenido COVID-19 ¿Ya ha sido dado/a de alta según lo establecido en el protocolo del Ministerio de Salud?',
                    respuestas:['Si','No','No tuve COVID-19'],
                  columnas:'12'
                  },
                  {
                     id:'8',
                    pregunta:'¿Es personal de Salud?',
                    respuestas:['Si','No'],
                  columnas:'12'
                  },
                  {
                     id:'9',
                    pregunta: '¿En los últimos 14 días estuvo en contacto estrecho con un caso confirmado de COVID-19?',
                    respuestas:['Si','No'],
                  columnas:'12'
                  }
                  ],    
            
      datosObtenidos:[],
    };
  },
  created:
           async function() {              
               await this.$http.get(process.env.VUE_APP_API +'/complejo/normas',
                 {params:{
                   idComplejo: this.idcomplejo
                    }
          })
       .then((response) => {
                          this.datosObtenidos= response.data
      })
  },
  computed: {},
  methods: {
    
  }
};
</script>

<style scoped>
.v-input-radio-group:not(.v-input--radio-group--row)
    .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 8px;
  }
</style>

