<template>
<v-form>
<v-container fluid class="ma-0 pa-0">     
        <v-card class="pa-0 mb-2"  > 
                <v-row dense class="caption"  no-gutters >
                        <v-col cols=3 md=1 class="pa-0 my-auto py-1 px-3 d-flex flex-no-wrap"  >                                       
                                <v-avatar size="70" >
                                        <v-img  :src="img_hotel" contain></v-img>
                                </v-avatar>                                             
                        </v-col>
                        <v-col cols=9 md=3 class="caption my-auto mx-0 px-2">                                         
                                <strong>{{voucher[0].Denominacion}}</strong><br>                                         
                                {{voucher[0].Direccion1}}<br>
                                {{voucher[0].Direccion2}} 
                        </v-col>                                
              


                        <v-col cols=12 md=2 align="center" class="ma-auto mb-4">                                                                               
                                <div>
                                        Reserva número: <strong> {{voucher[0].IdNroVoucher}} </strong>   
                                </div>
                                <div>
                                        <v-chip small color="green" dark>
                                                <v-avatar center>
                                                        <v-icon small>mdi-check-circle-outline</v-icon>
                                                </v-avatar >
                                                Estado: {{ voucher[0].EstadoVoucher }}
                                        </v-chip>
                                </div>
                        </v-col> 
                       <v-col cols=4 md=2  class="my-auto">
                                <v-row dense class="ma-auto" >
                                        <v-col class="ma-auto " cols=12 md=3>
                                                <v-avatar class="">
                                                        <v-icon >mdi-calendar-clock-outline</v-icon>
                                                </v-avatar >    
                                        </v-col>
                                        <v-col class="ma-auto  " cols=12 md=9>
                                                Check-in <br>                                               
                                                {{voucher[0].Llegada}}   
                                        </v-col>
                                </v-row>                                         
                        </v-col>
                        <v-col cols=4 md=2  class="my-auto">
                                <v-row dense class="ma-auto">
                                        <v-col class="ma-auto" cols=12 md=3>
                                                <v-avatar >
                                                        <v-icon >mdi-calendar-clock-outline</v-icon>
                                                </v-avatar >    
                                        </v-col>
                                        <v-col  class="ma-auto" cols=12 md=9>
                                                Check-out <br>                                               
                                                {{voucher[0].Salida}}   
                                        </v-col>
                                </v-row>                                         
                        </v-col>
                        <v-col cols=4 md=2  class="my-auto">
                                <v-row dense class="ma-auto">
                                        <v-col class="ma-auto" cols=12 md=3>
                                        <v-avatar >
                                                <v-icon >mdi-human-male</v-icon>
                                        </v-avatar >    
                                        </v-col>
                                        <v-col class="ma-auto " cols=12 md=9>
                                        Capacidad <br>                                               
                                        {{voucher[0].Capacidad}}   
                                        </v-col>
                                </v-row>                                         
                        </v-col>
                </v-row>                         
        <v-divider></v-divider>                        
        <v-row class="pa-0 ma-0" dense>                
                <v-col class="caption" cols=12 md=7>
                        <v-card dense class="pa-1">                            
                                <v-row dense>
                                        <v-col class="py-1 " cols=12 md=12>
                                                <template  v-if="voucher[0].precheckin===1">                                                        
                                                        <v-btn dark outlined rounded  color="red" small block @click="agregarHuespedes()">
                                                                <v-icon class="px-1" small>mdi-alert-circle-outline</v-icon>Web PreCheck-in Pendiente     
                                                        </v-btn>

                                                </template>
                                                <template  v-if="voucher[0].precheckin===0">                                                        
                                                        <v-btn  dark outlined rounded color="green" small block >
                                                                <v-icon class="px-1" small>mdi-check-circle-outline</v-icon>Web PreCheck-in completado       
                                                        </v-btn>

                                                </template>
                                        </v-col> 
                                        
                                        <template  v-if="voucher[0].precheckin===0">       
                                                <v-col md=7 class="">
                                                      <v-row v-for="(huesped,index) in huespedes" :key="huesped.index" dense class="pa-0 ma-0 pl-4">
                                                                {{index +1}}. {{huesped.nombre}} {{huesped.apellido}} ·  {{huesped.tipodocumento}} {{huesped.numerodocumento}}  
                                                        </v-row> 
                                                </v-col>
                                        </template>                                                                            
                                </v-row> 
                                <v-row dense>
                                      
                                              <v-col class="" cols=12 >
                                <v-btn v-if="voucher[0].precheckin==1" dark x-small :color="colorMenu" dense block class=" text-center" @click="agregarHuespedes()">   
                                        Completar WEB-Precheckin                                                                                                      
                                </v-btn>
                                <v-btn v-if="voucher[0].precheckin==0" dark x-small :color="colorMenu" dense block class=" text-center" @click="agregarHuespedes()">   
                                        Modificar WEB-Precheckin                                                                                                      
                                </v-btn>  
                                </v-col>  
                                    
                                </v-row>                                                             
                        </v-card>
                </v-col>              
                <v-col md=5 class="caption">
                        <v-row dense>
                                <v-col md=12>
                                        <v-card class="pa-2">
                                                 <strong>Observación:</strong> {{voucher[0].Observacionvoucher}}
                                        </v-card>    
                                </v-col>                                
                        </v-row>
                        <v-row dense>
                                <v-col class="ma-auto" cols=12 md=6 align=end>
                                <v-btn dark :color="colorMenu" dense block class=" text-center pa-2 caption">   
                                        <v-icon class="pr-2">mdi-file-download-outline</v-icon>
                                        <a href="https://www.rinconclub.com.ar/sistemas/prueba/voucher/46558.pdf" style="text-decoration:none; color:white"  download="Voucher.pdf">Descargar<br>Voucher</a>                                                              
                                </v-btn> 
                                </v-col>
                                <v-col class="ma-auto" cols=12 md=6 align=end>
                                        <v-btn dark :color="colorMenu" dense block class=" pa-2 caption" @click="protocolo()"> 
                                                <v-icon  class="pr-2">mdi-lotion-plus-outline</v-icon>
                                                        <span>Protocolos en<br>Servicios del Complejo</span>
                                        </v-btn> 
                                </v-col>
                        </v-row>                        
                </v-col>
        </v-row>
</v-card> 
<template>
        <v-dialog v-model=dialogProtocolo v-if=dialogProtocolo max-width="700"  >
                <v-card dense class="pa-3" >
                        <v-row dense>
                                <v-col class="caption">
                                        <servicios></servicios>
                                </v-col>
                        </v-row>
                        <v-row dense > 
                                <v-col>  
                                        <v-btn dark dense block :color=colorMenu @click=finalizarProtocolo()  >Continuar</v-btn> <!--:disabled="acepto == false"-->                                                                                                        
                                </v-col>                                                                       
                        </v-row>  
                </v-card>                       
        </v-dialog>
</template>
<template>
        <v-dialog v-model=dialogHuespedes v-if=dialogHuespedes max-width="1000" >
                <v-card >
                        <v-stepper vertical v-model="etapa" dense   >                                
                                <v-stepper-step class="pl-3 pr-2  py-4  "  :complete="etapa > 1" step="1" dense>
                                        Titular de la reserva
                                        <small >Verifique y/o complete los datos del titular de la reserva</small>
                                </v-stepper-step>
                                        <v-stepper-content step="1" dense class="ma-0 pa-0 px-3">
                                                <v-card flat dense v-for="(huesped) in huespedes" :key="huesped.pasajero" class="ma-0 pa-0">
                                                        <template  v-if="huesped.pasajero===1">
                                                                        <v-row  dense class="ma-0 pa-0 pt-2" >                                                                        
                                                                                <v-col cols="12" md="3"  >
                                                                                        <v-text-field class="caption" dense v-model="huesped.nombre"   persistent-placeholder   placeholder=" " label="Nombre"></v-text-field>
                                                                                </v-col>
                                                                                <v-col cols="12" md="3" >
                                                                                        <v-text-field class="caption" persistent-placeholder dense v-model="huesped.apellido"   placeholder=" " label="Apellido"></v-text-field>
                                                                                </v-col>
                                                                                <v-col  cols="12" md="2"> 
                                                                                        <v-select class="caption" persistent-placeholder dense v-model="huesped.nacionalidad" :items="nacionalidades" label="Nacionalidad"></v-select> 
                                                                                </v-col>
                                                                                <v-col cols=5 md=1 >
                                                                                        <v-select class="caption" persistent-placeholder dense v-model="huesped.tipodocumento" :items="documentos" label="Tipo Doc."></v-select>
                                                                                </v-col>
                                                                                <v-col cols=7 md=1  > 
                                                                                        <v-text-field class="caption" persistent-placeholder v-model="huesped.numerodocumento" dense  placeholder=" " label="Nº Doc." >
                                                                                        </v-text-field>
                                                                                </v-col > 
                                                                                <v-col dense cols="12" md="2">                                                                          
                                                                                        <v-menu dense
                                                                                                ref="menu"
                                                                                                v-model="menu"
                                                                                                :close-on-content-click="false"
                                                                                                transition="scale-transition"
                                                                                                offset-y
                                                                                                min-width="auto"
                                                                                                >
                                                                                                <template v-slot:activator="{ on, attrs }">
                                                                                                        <v-text-field class="caption" dense
                                                                                                                v-model="huesped.fnacimiento"
                                                                                                                label="F. Nacimiento"
                                                                                                                append-icon="mdi-calendar"
                                                                                                                readonly
                                                                                                                v-bind="attrs"
                                                                                                                v-on="on"
                                                                                                        ></v-text-field>
                                                                                                </template>
                                                                                                <v-date-picker
                                                                                                                ref="picker"
                                                                                                                v-model="huesped.fnacimiento"
                                                                                                                locale="es"
                                                                                                                :max="new Date().toISOString().substr(0, 10)"
                                                                                                                min="1920-01-01"
                                                                                                                @change="save">
                                                                                                </v-date-picker>
                                                                                        </v-menu>                                                                     
                                                                                </v-col>
                                                                        </v-row>                                                                       
                                                                        <v-row dense>
                                                                                <v-col cols=12 md=4>
                                                                                        <v-text-field class="caption" persistent-placeholder v-model="huesped.domicilio" dense placeholder=" " label="Domicilio" ></v-text-field>                      
                                                                                </v-col>
                                                                                <v-col cols=12 md=4>
                                                                                        <v-text-field class="caption" persistent-placeholder dense v-model="huesped.localidad" placeholder=" " label="Localidad"></v-text-field>                      
                                                                                </v-col>
                                                                                <v-col cols=6 md=2>
                                                                                        <v-select class="caption" persistent-placeholder v-model="huesped.provincia"  dense :items="provincias" label="Provincia"></v-select>                 
                                                                                </v-col>
                                                                                <v-col cols=6 md=2>
                                                                                        <v-select class="caption" persistent-placeholder  v-model="huesped.nacionalidad"  dense :items="nacionalidades" label="Pais"></v-select>          
                                                                                </v-col>
                                                                        </v-row>
                                                                        <v-row  dense> 
                                                                                <v-col cols=12 md=2 dense>
                                                                                        <v-text-field class="caption" persistent-placeholder  dense v-model="huesped.telefono" placeholder=" " label="Telefono"></v-text-field>                      
                                                                                </v-col>
                                                                                <v-col cols=12 md=4 dense>
                                                                                        <v-text-field class="caption" persistent-placeholder  dense v-model="huesped.email" placeholder=" " label="Email"></v-text-field>                      
                                                                                </v-col>
                                                                                <v-col cols=5 md=2>
                                                                                        <v-text-field class="caption" persistent-placeholder  dense v-model="huesped.patenteVehiculo" placeholder=" " label="Patente de vehiculo"></v-text-field>                      
                                                                                </v-col>                                                                                
                                                                        </v-row>
                                                                        <v-row dense>
                                                                                <v-col >
                                                                                        <v-btn block :color=colorMenu dark small @click="etapa=2">Continuar</v-btn>
                                                                                </v-col>
                                                                        </v-row>
                                                        </template>
                                                </v-card>
                                        </v-stepper-content>
                                        <v-stepper-step class="pa-0 pl-3 pr-2 pt-6 pb-0" :complete="etapa > 2" step="2" dense>
                                                        Huéspedes
                                                        <small>Agregue al resto de los huespedes de su reserva para {{voucher[0].Capacidad}}</small>                                                  
                                        </v-stepper-step>
                                        <v-stepper-content class="ma-0 pa-2" step="2" dense>
                                                <v-card dense class="">
                                                        <v-row v-for="(huesped,index) in huespedes" :key="huesped.index" dense class="pl-0 pt-1" >
                                                                <template>
                                                                        <v-chip outlined dense class="mx-1 mt-1" :color=colorMenu small>
                                                                                        <v-avatar left dense>
                                                                                                <v-icon small>mdi-check-circle-outline</v-icon>
                                                                                        </v-avatar >
                                                                                         {{index +1}}. {{huesped.nombre}} {{huesped.apellido}} |  {{huesped.tipodocumento}} {{huesped.numerodocumento}}  
                                                                                   <v-btn class="ml-1" icon x-small color="blue" @click="editar(item)" >                
                                                                                                        <v-icon small>mdi-pencil</v-icon>
                                                                                        </v-btn>
                                                                                        <v-btn icon x-small color="red" @click="borrar(item)">                
                                                                                                        <v-icon small>mdi-delete</v-icon>
                                                                                        </v-btn>                                                                 
                                                                        </v-chip>
                                                                </template>
                                                        </v-row>
                                                                <template>
                                                                        <v-dialog v-model=dialogProtocolo v-if=dialogProtocolo dense max-width="700" >
                                                                                <v-card dense class="pa-3" >
                                                                                        <v-row dense>
                                                                                                <v-col class="caption">
                                                                                                        <servicios></servicios>
                                                                                                </v-col>
                                                                                        </v-row>
                                                                                        <v-row dense > 
                                                                                                <v-col>  
                                                                                                        <v-btn dense block :color=colorMenu @click=finalizarProtocolo() dark >Continuar</v-btn> <!--:disabled="acepto == false"-->                                                                                                        
                                                                                                </v-col>                                                                       
                                                                                        </v-row>  
                                                                                </v-card>                                                                     
                                                                        </v-dialog>
                                                                </template>
                                                                <template>
                                                                        <v-dialog v-model=dialogHuespedes2 v-if=dialogHuespedes2 persistent width="600px">                 
                                                                                <v-card class="ma-0 pa-0" dense> 
                                                                                        <v-tabs v-model="tabs" dense grow centered >
                                                                                                <v-tab dense class="caption" centered>Datos <br>del Huesped</v-tab>
                                                                                                <v-tab dense class="caption" >Declaración <br>Jurada de Salud</v-tab>
                                                                                        </v-tabs>
                                                                                        <v-tabs-items v-model="tabs">                                                                              
                                                                                                <v-tab-item>
                                                                                                        <v-card class="ma-0 pa-5"> 
                                                                                                                <v-row dense class="caption">
                                                                                                                        <v-col cols=12 md=4>
                                                                                                                                <v-text-field  persistent-placeholder dense  v-model="editItem.nombre"  placeholder=" " label="Nombre"></v-text-field>
                                                                                                                        </v-col >
                                                                                                                        <v-col cols=12 md=4 > 
                                                                                                                                <v-text-field  persistent-placeholder dense  v-model="editItem.apellido" placeholder=" " label="Apellido"></v-text-field>
                                                                                                                        </v-col>
                                                                                                                        <v-col cols=12 md=3> 
                                                                                                                                <v-text-field persistent-placeholder  type="date" dense v-model="editItem.fnacimiento" placeholder=" " label="F. Nacimiento"></v-text-field> 
                                                                                                                        </v-col>
                                                                                                                        <v-col cols=12 md=3> 
                                                                                                                                <v-select dense persistent-placeholder  :items="nacionalidades" placeholder=" " label="Nacionalidad" v-model="editItem.nacionalidad"></v-select> 
                                                                                                                        </v-col >
                                                                                                                        <v-col cols=12  md=2>
                                                                                                                                        <v-select   dense v-model="editItem.tipoDocumento" :items="documentos" label="Tipo Doc."></v-select>
                                                                                                                        </v-col>
                                                                                                                        <v-col cols=12  md=3 > 
                                                                                                                                <v-text-field  persistent-placeholder v-model="editItem.numeroDocumento" dense  placeholder=" " label="Nº Documento" >
                                                                                                                                </v-text-field>
                                                                                                                        </v-col > 
                                                                                                                </v-row>
                                                                                                                <v-row dense>
                                                                                                                        <v-col>                                                                                                               
                                                                                                                                        <v-btn :color=colorMenu x-small block dark dense @click="closeHuespedes()">Cancelar</v-btn>
                                                                                                                        </v-col>
                                                                                                                </v-row>
                                                                                                                <v-row dense>
                                                                                                                        <v-col>        
                                                                                                                                        <v-btn :color=colorMenu small block  @click="proximoTabs()" dark >Continuar</v-btn>
                                                                                                                        </v-col>                                                                                                          
                                                                                                                </v-row>
                                                                                                        </v-card>
                                                                                                </v-tab-item>
                                                                                                <v-tab-item dense>
                                                                                                        <v-card class="pa-4 caption" dense>
                                                                                                                <ddjj></ddjj>
                                                                                                                <v-card-actions dense>                                                                                                                      
                                                                                                                                <v-btn block :color=colorMenu class="pa-0" dark  dense  @click="agregarItem(editIem,index+1)">Continuar</v-btn>
                                                                                                                </v-card-actions>                                                                                
                                                                                                        </v-card>                                                                                               
                                                                                                </v-tab-item>
                                                                                        </v-tabs-items>    
                                                                                </v-card>
                                                                        </v-dialog>
                                                                </template>                                                       
                                                        <v-row dense>
                                                                <v-btn small class="ma-3" color="primary" @click=agregarHuespedes2>                
                                                                        <v-icon small left>mdi-account-plus-outline</v-icon> Agregar otro Huesped
                                                                </v-btn> 
                                                        </v-row>
                                                        <v-divider class="my-2 pa-0"></v-divider>                                                                                        
                                                        <v-row dense>
                                                                <v-col cols=12 md=2>
                                                                        <v-btn block :color=colorMenu dark  x-small @click="etapa =1 ">Atras</v-btn>
                                                                </v-col>
                                                        
                                                                <v-col cols=12 md=10>
                                                                        <v-btn block :color=colorMenu dark small @click="finalizarcarga()">Finalizar</v-btn>
                                                                </v-col>                                                                                                                
                                                        </v-row>                                                                                                                
                                                </v-card> 
                                        </v-stepper-content>
                                </v-stepper>
                </v-card>
        </v-dialog>
</template>
<template>
        <v-row dense>        
                <v-col md=5>
                        <v-card class="mt-0 pt-2" dense>
                                <v-row  dense>
                                        <v-col class="ma-auto pl-3 mb-2 " cols=3>                                         
                                                        <v-img contain dense width="100px" :src="img_travel"></v-img>                                                
                                        </v-col>                                
                                        <v-col  cols=9  class="ma-auto  overline text-center   " >                                       
                                                Servicios Contratados                                    
                                        </v-col>
                                </v-row> 
                                <v-row dense>
                                        <v-col md=12 class="ma-auto pa-auto">
                                                 <serviciosContratados></serviciosContratados>     
                                        </v-col>
                                </v-row>
                                <v-row dense class="mx-2">
                                        <v-col cols=12 class="ma-0 pa-0 mb-1">
                                                <v-btn dark :color="colorMenu" block x-small @click="agregarServicio()">
                                                        Agregar Servicio
                                                </v-btn>
                                        </v-col>
                                </v-row>
                        </v-card>
                </v-col>
                <v-col cols=12 md=7>
                       <v-card max-width="400" class="mt-1 pt-2" >                       
                                <v-row class="px-0 pb-2">
                                        <v-col class="ma-0 py-0">
                                        <v-carousel cycle interval=4000 height="180" hide-delimiters hide-delimiter-background show-arrows-on-hover>
                                                <v-carousel-item
                                                                v-for="(slide, i) in slides"
                                                                :key="i"
                                                                :src="slide.src"
                                                                >  
                                                                <v-row >
                                                                        <v-col></v-col>
                                                                        <v-col  cols=6 class="text-center" >
                                                                                <v-card  dark color="rgb(72, 103, 133, 0.8)" class="caption pa-1 elevation-5 ma-2" >
                                                                                                {{slide.texto}} 
                                                                                        <v-btn dense class="px-1 mx-1" color="white" outlined x-small> + Info</v-btn>                                                                                          
                                                                                </v-card>  
                                                                        </v-col>
                                                                </v-row>                        
                                                </v-carousel-item>
                                        </v-carousel>
                                </v-col>
                                </v-row>
                                <v-row  dense  >
                                        <v-col class="ma-0 py-0  ml-2" cols=5 >                                         
                                                <v-img contain width="140px"  :src="img_travel"></v-img>                                                
                                        </v-col>                                
                                        <v-col cols=6 dense class="ma-auto" >                                       
                                                <v-btn dark block :color="colorMenu" x-small @click="agregarServicio()">
                                                        Reserve su lugar
                                                </v-btn>                                    
                                        </v-col>
                                </v-row>
                        </v-card>
                </v-col>
        </v-row>  
         <v-dialog v-model=dialogAgregarServicios v-if=dialogAgregarServicios width="700" max-width="auto" >
        
            <v-card class="ma-0 pa-1">             
                    <serviciosContratar></serviciosContratar>        
          
            </v-card>
            
    </v-dialog>          
</template>

</v-container>
</v-form>
</template>         

 
<script>
import datosReservas from "@/base/reservas.json";
import datosHuespedes from "@/base/huespedes.json";
import ddjj from '@/components/checkin/ddjj'
import servicios from '@/components/checkin/protocolosServicios'
import serviciosContratados from '@/components/checkin/serviciosContratados.vue'
import serviciosContratar from '@/components/checkin/serviciosContratar.vue';
import datosContratados from '@/base/contratadosServiciosTravel.json'

export default {
      components:{servicios,ddjj,serviciosContratados,serviciosContratar},
    created:         
                 async function() {   
                                this.huespedes= datosHuespedes.filter((item) => {
                                        return item.idreserva==205823
                                })
                                this.voucher=datosReservas.filter((item) => {
                                        return item.IdNroVoucher==205823
                                })
                                this.contratados=datosContratados.filter((item) => {
                                        return item.idhuesped==2
                                })
                                this.voucher[0].precheckin=1
                                this.$store.commit('grabarLayout', 'dashboard-layout')
                                
  }
  ,

  data(){
      return{   
                dialogProtocolo:false,
                dialogHuespedes:false,
                dialogHuespedes2:false,
                dialogAgregarServicios:false,
                etapa:1,
                tabs:null,
                editItem: {},  
                documentos:["DNI","Pasaporte"],
                provincias:["Buenos Aires","CABA"],
                nacionalidades:["Argentina","Brasil","Chile","Uruguay"],
                colorMenu:"#0E365C",
                img_hotel:require("@/assets/rda3.png"),
                img_travel:require("@/assets/rincontravel2.png"),
                datosObtenidos:'',
                expanded: [],
                precheckin:true,
                slides: [
                         {
                          src: require("@/assets/vuelo6.jpg"),
                          texto: "Tickets"       
                        },
                        {
                        src: require("@/assets/travel6.jpg"),
                        texto: "Pases de Esquí"
                        },
                        {
                          src: require("@/assets/transfer7.jpg"),
                          texto: "Traslados"       
                        },
                        {
                          src: require("@/assets/rent4.jpg"),
                          texto: "Alquiler de vehiculos"       
                        },
                        {
                          src: require("@/assets/excursiones1.jpg"),
                          texto: "Excursiones"       
                        },],
                voucher:[],
                huespedes:[],
                contratados:[]
      }
  },
  methods:{
          protocolo(){
                      this.dialogProtocolo=true
          },
           agregarServicio() {
                             this.dialogAgregarServicios=true
          },
          finalizarcarga(){
                      this.voucher[0].precheckin=0
                  
                       this.dialogHuespedes=false
                     

          },
          finalizarProtocolo(){
                        this.dialogProtocolo=false
          },
          proximoTabs(){
                this.tabs=1
        },
          agregarHuespedes(){   
                                this.etapa=1
                                this.huespedes[0].domicilio=this.voucher[0].Domicilio1
                                this.huespedes[0].localidad=this.voucher[0].Domicilio2
                                this.huespedes[0].provincia=this.voucher[0].Domicilio3
                                this.huespedes[0].telefono=this.voucher[0].TelefonoCelular
                                this.huespedes[0].email=this.voucher[0].email
                                this.huespedes[0].fnacimiento=this.voucher[0].FechaNacimiento
                                this.dialogHuespedes=true

                                
                        
          },
          agregarHuespedes2(){
                                this.dialogHuespedes2=true
          },
          closeHuespedes(){
                            this.dialogHuespedes=false
          },
          closeHuespedes2(){
                            this.dialogHuespedes2=false
          },
          agregarItem(item,indice){
                this.dialog=false
                this.tabs=0                                
                this.editItem.pasajero=indice+1               
                this.huespedes.push(this.editItem)
               this.dialogCovid=true
               this.closeHuespedes
                this.editItem={}
            
        },
          descargarVoucher(){}
  }, 
  
}

</script>