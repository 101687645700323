<template>    
          <v-row class="mx-1 caption" dense>
                 <v-col   v-for="(item, i) in serviciosContratados" :key="i" cols="12" md="12" class="mb-2">
                        <v-card dense class="borde"  >
                                <v-row dense>
                                      <v-col cols=3 md=2 align="center">
                                          <v-avatar dense  size="50">
                                                  <v-img  :src="item.img"></v-img>
                                        </v-avatar>
                                      </v-col>
                                      <v-col cols=9 md=10>                                     
                                          <strong>{{item.servicio}} x {{item.cantidad}}</strong>
                                            <v-chip class="ml-2"  dark color="green" x-small dense>Confirmado</v-chip>
                                            <v-btn class="ml-2" dark outlined  color="green" x-small>
                                                    <v-icon  small>mdi-file-download-outline</v-icon>     
                                            </v-btn>
                                            <v-btn class="ml-2" dense  dark outlined  color="green" x-small   >
                                                  + INFO    
                                            </v-btn> 
                                            <div>{{item.detalle}}                                   
                                            </div>
                                            <div>Fecha: {{item.fecha}}
                                            </div>   
                                      </v-col>
                                </v-row>
                        </v-card>           
                 </v-col>                 
          </v-row>
</template>

<script>
export default {
  name:"serviciosContratados",
  data(){
       return{
              dialog:true,       
              serviciosContratados:[
                                {
                                    img:require("@/assets/transfer0.png"),
                                    servicio:'Transfer',
                                    detalle:'Desde Aeropuerto Chapelco a Complejo Rincon de los Andes',
                                    cantidad:4,
                                    fecha:'10/10/2021 13:30',
                                    observacion: ''
                                },
                                {
                                    img:require("@/assets/rent0.jpg"),
                                    servicio:'Alquiler de vehiculo',
                                    detalle: 'Vehiculo tipo sedan x 3 dias',
                                    cantidad:1,
                                    fecha:'12/10/2021 14:30',
                                    observacion:''
                                }
                            ],        
       }
  },
  methods:{
          colorEstado (estado) {
                                if (estado=="Pendiente") return 'red'
                                 else if (estado =="Completado") return 'green'
                                else return 'green'
                                },
  }
}
</script>
<style scoped>
    .borde {
 border-left: 5px solid green !important;
}
</style>